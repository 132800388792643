<template>
  <div class="rch-main">
    <v-col v-if="nonet">
      <v-row>
        <v-col cols="12" class="d-flex justify-center text-center pt-10">
          <v-img
            lazy-src="../assets/nonet.png"
            width="200"
            max-width="250"
            src="../assets/nonet.png"
            cols="12"
          ></v-img>
        </v-col>
        <v-col cols="12" class="justify-center text-center">
          <h4 cols="12">Connect to the internet</h4>
          <p>You're offline. Check your connection</p>
          <v-btn text color="primary" @click="$router.go()"> Retry </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <v-card>
        <v-card-title class="primary--text">
          <h3>Running Log History</h3>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="history" :search="search">
          <template v-slot:item.is_fuel="{ item }">
            <div v-if="parseInt(item.is_fuel)">Yes</div>
            <div v-else>No</div>
          </template>
          <template v-slot:item.is_oil="{ item }">
            <div v-if="parseInt(item.is_oil)">Yes</div>
            <div v-else>No</div>
          </template>
          <template v-slot:item.approved="{ item }">
            <span
              v-if="parseInt(item.approved)"
              class="teal lighten-5 rounded-circle"
            >
              <v-icon small color="green darken-2">
                mdi-check-circle-outline
              </v-icon>
            </span>
            <span v-else class="pink lighten-5 rounded-circle">
              <v-icon small color="red darken-2"> mdi-timer-sand-empty </v-icon>
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              text
              color="primary"
              class="mx-0 px-0"
              dark
              @click="more(item.id)"
            >
              More
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <v-dialog v-model="print_dialog" max-width="290">
        <v-card>
          <div id="fuel_print" style="display: flex; float: right">
            <div
              class="d-none"
              style="
                border-top: 1px solid black;
                border-right: 1px solid black;
                width: 200px;
                height: 100px;
                margin-right: 20px;
              "
            ></div>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="item in print_values" :key="item.name">
                      <td style="line-height: 1.6">{{ item.title }}</td>
                      <td>{{ item.value }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="printing"> Print </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="detail_log" scrollable max-width="500px">
        <v-card v-if="detail_history">
          <v-card-title>Runnninng log : {{ detail_history.id }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 100%">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Date</td>
                    <td>{{ detail_history.date }}</td>
                  </tr>
                  <tr>
                    <td>Vehicle Number</td>
                    <td>{{ detail_history.vehicle_no }}</td>
                  </tr>
                  <tr>
                    <td>Time Out</td>
                    <td>{{ detail_history.time_out }}</td>
                  </tr>
                  <tr>
                    <td>Time In</td>
                    <td>{{ detail_history.time_in }}</td>
                  </tr>
                  <tr>
                    <td>Meter Reading Out</td>
                    <td>{{ detail_history.meter_out }}</td>
                  </tr>
                  <tr>
                    <td>Meter Reading In</td>
                    <td>{{ detail_history.meter_in }}</td>
                  </tr>
                  <tr>
                    <td>No of Km</td>
                    <td>{{ detail_history.no_of_km }}</td>
                  </tr>
                  <tr>
                    <td>Project code</td>
                    <td>{{ detail_history.project_code }}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{{ detail_history.description }}</td>
                  </tr>
                  <tr>
                    <td>Image</td>
                    <td><img :src="detail_history.image" width="100%" /></td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <td>Daily Check List</td>
                    <td v-if="detail_history.daily_chek_list">
                      <span
                        v-for="check in detail_history.daily_chek_list"
                        :key="check"
                      >
                        {{ check }},
                      </span>
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Daily Check Defects</td>
                    <td v-if="detail_history.defets_if_any">
                      {{ detail_history.defets_if_any }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Daily Check Others</td>
                    <td v-if="detail_history.other_checks">
                      {{ detail_history.other_checks }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Fuel</td>
                    <td v-if="detail_history.fuel">
                      {{ detail_history.fuel }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>No of Litres</td>
                    <td v-if="detail_history.fuel_no_of_liters">
                      {{ detail_history.fuel_no_of_liters }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Meter Reading (Present)</td>
                    <td v-if="detail_history.fuel_meter_present">
                      {{ detail_history.fuel_meter_present }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Meter Reading at Last filling</td>
                    <td v-if="detail_history.fuel_meter_last">
                      {{ detail_history.fuel_meter_last }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>No of KMS since last filling</td>
                    <td v-if="detail_history.fuel_no_of_kms">
                      {{ detail_history.fuel_no_of_kms }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>KMS PER LITER</td>
                    <td v-if="detail_history.fuel_kms_per_liter">
                      {{ detail_history.fuel_kms_per_liter }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Fuel Amount</td>
                    <td v-if="detail_history.fuel_amount">
                      {{ detail_history.fuel_amount }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr v-if="detail_history.fuel">
                    <td colspan="2" align="right">
                      <v-btn
                        color="primary"
                        class="mx-3"
                        dark
                        @click.stop="printView(detail_history.id)"
                      >
                        Fuel Print
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>Oil No of Litres</td>
                    <td v-if="detail_history.oil_no_of_liters">
                      {{ detail_history.oil_no_of_liters }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Oil Meter Reading</td>
                    <td v-if="detail_history.oil_meter_reading">
                      {{ detail_history.oil_meter_reading }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Fuel Amount</td>
                    <td v-if="detail_history.oil_amount">
                      {{ detail_history.oil_amount }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                  <tr>
                    <td>Approved by</td>
                    <td v-if="detail_history.approved_by">
                      {{ detail_history.approved_by }}
                    </td>
                    <td v-else>_</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="detail_log = false">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="editItem(detail_history.id)"
              v-if="
                // parseInt(detail_history.open_for_edit) ||
                new Date(detail_history.date) >=
                  new Date(new Date().setDate(new Date().getDate() - 2))
              "
            >
              Edit
            </v-btn>
            <!-- <v-btn
              color="blue darken-1"
              text
              @click="cancelEditRequest(detail_history.id)"
              v-else-if="parseInt(detail_history.edit_request)"
            >
              Cancel Request
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="editRequest(detail_history.id)"
              v-else
            >
              Request Edit
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import printJS from "print-js";
const axios = require("axios").default;
export default {
  name: "Dashboard",
  components: {
    // TabBar,
  },
  data() {
    return {
      nonet: false,
      print_dialog: false,
      detail_log: false,
      detail_history: null,
      search: "",
      headers: [
        { text: "Date", value: "date", align: "start" },
        { text: "Vehicle Number", value: "vehicle_id" },
        { text: "Project", value: "project_id" },
        { text: "Time Out", value: "time_out" },
        { text: "Time In", value: "time_in" },
        { text: "Meter reading Out", value: "meter_out" },
        { text: "Meter reading In", value: "meter_in" },
        { text: "No of km", value: "no_of_km" },
        { text: "Fuel", value: "is_fuel" },
        { text: "Oil", value: "is_oil" },
        { text: "Approved", value: "approved" },
        { text: "Action", value: "actions", sortable: false, align: "end" },
      ],
      print_values: [],
      history: [],
      snackbar: false,
      text: ``,
      timeout: 3000,
    };
  },
  methods: {
    more(id) {
      axios
        .get(this.$backend_url + "employee-log-history-detail", {
          params: {
            remember_token: JSON.parse(localStorage.imso_user)[
              "remember_token"
            ],
            email: JSON.parse(localStorage.imso_user)["email"],
            log_id: id,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.detail_history = response.data.detail_history;
          }
        })
        .catch((res) => {
          console.log("Errors:", res);
          this.snackbar = true;
          this.text = `Error occured while receiving data.`;
        });
      this.detail_log = true;
    },
    editItem(id) {
      this.$router.push("/running-log/edit/" + id);
    },
    editRequest(id) {
      axios
        .put(this.$backend_url + "employee-log-edit-request", {
          remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
          email: JSON.parse(localStorage.imso_user)["email"],
          log_id: id,
        })
        .then((response) => {
          if (response.data.success) {
            this.detail_history.edit_request = 1;
            this.snackbar = true;
            this.text = `Edit request has been sent.`;
          }
        })
        .catch((res) => {
          console.log("Errors:", res);
          this.snackbar = true;
          this.text = `Error occured while sending data.`;
        });
    },
    cancelEditRequest(id) {
      axios
        .put(this.$backend_url + "employee-log-cancel-edit-request", {
          remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
          email: JSON.parse(localStorage.imso_user)["email"],
          log_id: id,
        })
        .then((response) => {
          if (response.data.success) {
            this.detail_history.edit_request = 0;
            this.snackbar = true;
            this.text = `Edit request cancelled`;
          }
        })
        .catch((res) => {
          console.log("Errors:", res);
          this.snackbar = true;
          this.text = `Error occured while sending data.`;
        });
    },
    printView() {
      this.print_values = [
        {
          title: "Date:",
          value: this.detail_history.date,
        },
        {
          title: "Vehicle number:",
          value: this.detail_history.vehicle_no,
        },
        {
          title: "Meter reading:",
          value: this.detail_history.fuel_meter_present,
        },
        {
          title: "Amount:",
          value: this.detail_history.fuel_amount,
        },
        {
          title: "Litre:",
          value: this.detail_history.fuel_no_of_liters,
        },
        {
          title: "Signature:",
        },
      ];
      this.print_dialog = true;
    },
    printing() {
      const style =
        "@page { float: right; padding: 50px; size: A4 portrait; line-height: 2}";
      printJS({
        printable: "fuel_print",
        type: "html",
        header: null,
        style: style,
        scanStyles: false,
        documentTitle: "IMSO - Running Chart History",
        onPrintDialogClose: () => console.log("The print dialog was closed"),
        onError: (e) => console.log(e),
      });
      this.print_dialog = false;
    },
  },
  mounted() {
    axios
      .get(this.$backend_url + "employee-log-history", {
        params: {
          remember_token: JSON.parse(localStorage.imso_user)["remember_token"],
          email: JSON.parse(localStorage.imso_user)["email"],
        },
      })
      .then((response) => {
        if (response.data.success) {
          this.history = response.data.history;
        }
        this.nonet = false;
      })
      .catch((res) => {
        console.log("Errors:", res);
        this.nonet = true;
        this.snackbar = true;
        this.text = `No Internet`;
      });
  },
};
</script>

<style>
.rch-main {
  padding: 20px;
  margin-left: 50px;
  width: calc(100vw - 60px);
  min-height: calc(100vh - 200px);
}
@media only screen and (max-width: 960px) {
  .rch-main {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .rch-main {
    padding: 20px;
    margin-left: 0px;
    width: 100vw;
  }
}
</style>